import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Helmet}  from 'react-helmet';
import ReactPlayer from 'react-player';

import AboutUsButton from './AboutUsButton';
import * as styles from './Client.module.scss';
import * as slideStyles from './Slideshow.module.scss';
import TextBlock from './TextBlock';
import TypedText from './TypedText';

const clientMediaPropTypes = {
  platform: PropTypes.oneOf(['desktop', 'mobile']).isRequired,
  url: PropTypes.string.isRequired,
  all: PropTypes.bool.isRequired,
  isPlaying: PropTypes.bool,
  hidden: PropTypes.bool,
};

const ClientImage = ({ platform, url, all, hidden }) => (
  <div
    className={`${styles.image} ${all ? styles.all : styles[platform]} ${hidden ? styles.hidden : ''}`}
    style={{backgroundImage: `url(${url})`}}
  ></div>
);

ClientImage.propTypes = clientMediaPropTypes;

const ClientVideo = ({ platform, url, all, isPlaying, hidden }) => {
  const video = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    isPlaying && isVisible ? video.current?.play() : video.current?.pause();
  }, [isPlaying, isVisible]);

  useEffect(() => {
    const handleResize = () => {
      setIsVisible(() => all || (platform === 'desktop' && window.innerWidth >= 640) || (platform === 'mobile' && window.innerWidth < 640)); // eslint-disable-line no-undef
    };

    handleResize();
    window.addEventListener('resize', () => handleResize()); // eslint-disable-line no-undef
  });

  return (
    <div
      className={`${styles.video} ${all ? styles.all : styles[platform]} ${hidden ? styles.hidden : ''}`}
    >
      <video
        src={url}
        playsInline
        muted
        loop
        ref={video}
      ></video>
    </div>
  );
};

const ClientVimeoVideo = ({ platform, url, all, isPlaying, hidden }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [muted, setMuted] = React.useState(true);
  const player = useRef();

  useEffect(() => {
    const handleResize = () => {
      setIsVisible(() => all || (platform === 'desktop' && window.innerWidth >= 640) || (platform === 'mobile' && window.innerWidth < 640)); // eslint-disable-line no-undef
    };

    handleResize();
    window.addEventListener('resize', () => handleResize()); // eslint-disable-line no-undef

    // if (!isPlaying && muted) { // Reset the muted flag when we navigate away
    //   setMuted(false);
    // }
  });

  const handleError = useCallback(() => {
    setMuted(true);
    player.current.getInternalPlayer()?.setMuted(true);
    player.current.getInternalPlayer()?.play();
  });
  const unmute = useCallback(() => {
    setMuted(false);
    player.current.getInternalPlayer()?.setMuted(false);
    player.current.getInternalPlayer()?.play();
  });
  const playing = isPlaying && isVisible && !hidden;

  return (
    <div
      className={`${styles.video} ${all ? styles.all : styles[platform]} ${hidden ? styles.hidden : ''}`}
    >
        {/* {muted
          ? <button className={`${styles.unmute}`} onClick={unmute}>unmute</button>
          : <></>
        } */}

        <ReactPlayer
          ref={player}
          url={url}
          playing={playing}
          muted={muted}
          volume={playing && !muted ? 1 : 0}
          controls={false}
          onError={handleError}
          loop={playing}
          playsinline={true}
          autoPlay={true}
        ></ReactPlayer>
    </div>
  );
};

ClientVimeoVideo.propTypes = clientMediaPropTypes;
ClientVideo.propTypes = clientMediaPropTypes;

const isImage = media => media.raw.kind === 'image' || ['png', 'jpg', 'jpeg'].some(ext => media.raw.url.toLowerCase().includes(`.${ext}`));
const isVideo = media => media.raw.kind === 'video' || ['mp4', 'mpeg4', 'mp4', 'm3u8'].some(ext => media.raw.url.toLowerCase().includes(`.${ext}`));
const isVimeo = media => media.raw.url.toLowerCase().includes('vimeo.com');
const getKind = media => isVimeo(media) ? 'vimeo' : isImage(media) ? 'image' : isVideo(media) ? 'video' : undefined;
const getComponent = kind => kind === 'vimeo' ? ClientVimeoVideo : kind === 'image' ? ClientImage : kind === 'video' ? ClientVideo : undefined;

const Client = ({ client, isActive, isPrevious, isNext, isPrivate }) => {
  if (!client) { return null; }

  const desktopMedia = client.primary.desktop_media;
  const mobileMedia = client.primary.mobile_media;
  const assets = [];

  if (desktopMedia.raw.url) {
    const kind = getKind(desktopMedia);
    const Component = getComponent(kind);

    if (kind) {
      assets.push(<Component platform='desktop' url={desktopMedia.raw.url} all={!mobileMedia.raw.url} key={`desktop-${kind}`} isPlaying={isActive} hidden={!isActive && !isPrevious && !isNext} />);
    }
  }

  if (mobileMedia.raw.url) {
    const kind = getKind(mobileMedia);
    const Component = getComponent(kind);

    if (kind) {
      assets.push(<Component platform='mobile' url={mobileMedia.raw.url} all={!desktopMedia.raw.url} key={`mobile-${kind}`} hidden={!isActive && !isPrevious && !isNext} />);
    }
  }

  return (
    <>
      {isActive && !isPrivate && <Helmet><title>{client.primary.caption.text}</title></Helmet>}
      {isPrivate && <Helmet><meta name={'robots'} content={'noindex, nofollow, noimageindex'} /></Helmet>}
      <main className={`${slideStyles.slideWrapper} ${isActive && slideStyles.slideWrapperActive}`}>
        {!isPrivate &&
          <div className={`${slideStyles.slideTextBlock} ${isActive && slideStyles.slideTextBlockActive}`}>
            <TextBlock>
              <AboutUsButton mobile={false}></AboutUsButton>
              <TypedText text={client.primary.caption.text} isActive={isActive}></TypedText>
            </TextBlock>
          </div>
        }

        <div className={`${slideStyles.slideContent} ${isActive && slideStyles.slideContentActive}`}>
          {assets.map(asset => asset)}
        </div>
      </main>
    </>
  );
};

Client.propTypes = {
  client: PropTypes.object.isRequired,
  isActive: PropTypes.bool,
  isPrevious: PropTypes.bool,
  isNext: PropTypes.bool,
  isPrivate: PropTypes.bool,
};

export default Client;
